<!-- 电能设备详情页 -->
<template>
  <div id="facilityEquipmentDetails" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <!-- 顶栏 -->
    <div class="header">
        <!-- 左上角头部开关 <span>{{ equipmentName }}</span>   -->
        <div class="header-title">
            <img :src="getImages" />
            <a-input :class="namecls" v-model="equipmentName" :title="$t('energy.enedata306')"></a-input>  
            <a-tooltip :title="$t('energy.enedata306')" placement="right" style="color: #7682ce;">
                <a-icon type="question-circle-o" />
            </a-tooltip>      
        </div>
       <div>
          <a-button type="primary" @click="issueAnnounceDialog" v-if="lcdmonitorNo>0">{{$t('energy.enedata402')}}</a-button>
          <a-divider type="vertical"  v-if="lcdmonitorNo>0" />
          <!-- 返回上一级箭头 -->
          <a-icon
            type="close" 
            :style="{ fontSize: '20px', color: '#7682CE' }"
            @click="goBack"
          />
        </div>
    </div>
    <div class="main" ref="viewBox">
        <a-back-top :visibilityHeight="200" :target="() => this.$refs.viewBox" />
        <!-- 曲线图 -->
        <div class="content" v-if="measure.pvTrendIdentifier">
          <div :id="facilityEquipmentChart" :style="{width: '100%', height: '400px'}"></div>
        </div >
    <!-- 统计面板 累计信号信息-->
    <div class="statistics" v-if="objectType==23">
      <!-- 计量 -->
      <div class="measure">
        <div class="measure-name">
          <a-input :class="namecls" v-model="accum.signalName" :title="$t('energy.enedata401')"></a-input> 
          <a-tooltip :title="$t('energy.enedata306')"  placement="right" style="color: #7682ce;font-size:18px; padding-top: 6px; padding-left: 5px;">
              <a-icon type="question-circle-o" />
          </a-tooltip> 
        </div>
        <!-- 计量值 -->
        <span class="measure-header">{{$t('energy.enedata105')}} ({{measure.engineeringUnits}})</span>
        <div class="measure-main">
          <p class="measure-main-t">{{measure.doublePresentValue}}</p>
          <span class="measure-main-b">{{measure.changeDatetime}}</span>
          <div class="measure-footer">
            <div>
              <p class="measure-footer-p">{{measure.presentValue}}</p>
              <!-- 计量计数器 -->
              <span class="measure-footer-s">{{$t('energy.enedata106')}}</span>
            </div>
            <div>
              <p class="measure-footer-p">{{measure.cumulativeValue}}</p>
              <!-- 累计数据 -->
              <span class="measure-footer-s">{{$t('energy.enedata107')}}</span>
            </div>
            <div>
              <p class="measure-footer-p">{{measure.monthlyCount}}</p>
              <!-- 月数据 -->
              <span class="measure-footer-s">{{$t('energy.enedata110')}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 时数据和日数据 -->
      <div class="statistics-realTime">
        <div class="circle">
          <p class="circle-p">{{measure.hourlyCount}}</p>
          <span class="circle-s">{{$t('energy.enedata108')}}</span>
        </div>
        <div class="circle">
          <p class="circle-p">{{measure.dailyCount}}</p>
          <span class="circle-s">{{$t('energy.enedata109')}}</span>
        </div>
      </div>
      <ul class="operate">
        <li class="operate-li">
          <!-- 维护登记 -->
          <span>{{$t('energy.enedata111')}}</span>
          <div>
            <a-switch :checked="measure.maintenance" @change="handleChange(0)"/>
          </div>
        </li>
        <li class="operate-li border">
            <!-- 权重 -->
            <span>{{$t('energy.enedata112')}}：{{measure.coefficient}}{{measure.engineeringUnits}}</span>
            <!-- 计量单位 -->
            <span>{{$t('energy.enedata113')}}：{{measure.engineeringUnits}}</span>
        </li>
        <li class="operate-li ">
       
            <!-- 显示对象 -->
            <span>{{$t('energy.enedata012')}}：</span>
            <a-select v-model="disp" style="width: 150px" @change="handleDisplayChange"> 
                <a-select-option
                    v-for="(itme, index) in measure.displayTypes"
                    :key="index" :value="itme.no" >
                    {{ itme.text }}
                </a-select-option>
            </a-select>
         
        </li>
        <li class="operate-li border">
          <!-- 声音类别 -->
          <span>{{$t('energy.enedata114')}}：</span>
          <a-select v-model="sound" style="width: 150px" @change="handleSoundChange">
              <a-select-option
                  v-for="(itme, index) in measure.alarmSounds"
                  :key="index" :value="itme.no" >
                  {{ itme.text }}
              </a-select-option>
          </a-select>
        </li>
      </ul>
    </div>
    <!-- 统计面板  模拟  -->
    <div class="statistics" v-if="objectType==0 || objectType==1|| objectType===2">
      <!-- 统计面板  模拟 -->
      <!-- 计量 -->
      <div class="measure">
        <div class="measure-name">
          <a-input :class="namecls" v-model="accum.signalName" :title="$t('energy.enedata401')"></a-input> 
          <a-tooltip :title="$t('energy.enedata306')"  placement="right" style="color: #7682ce;font-size:18px; padding-top: 6px; padding-left: 5px;">
              <a-icon type="question-circle-o" />
          </a-tooltip> 
        </div>

        <span class="measure-header">{{$t('energy.enedata105')}}({{measure.engineeringUnits}})</span>
        <div class="measure-main">
          <p class="measure-main-t">{{measure.presentValue}}</p>         
        </div>
         <span class="measure-main-b">{{measure.changeDatetime}}</span>
        <div class="measure-footer">
          <!-- 取值范围 -->
              {{$t('energy.enedata131')}}：{{measure.lowValueRange}}~{{measure.highValueRange}}
          </div>
      </div>
      <!-- 时数据和日数据 -->
      <div class="statistics-realTime">
        
      </div>
      <ul class="operate">
        <li class="operate-li">
          <span> {{$t('energy.enedata111')}}</span>
          <div>
            <a-switch v-model="measure.maintenance" /> 
          </div>
        </li>
        <li class="operate-li border">
            <span> {{$t('energy.enedata113')}}：{{measure.engineeringUnits}}</span>
        </li>
        <li class="operate-li">
          
           <span> {{$t('energy.enedata114')}}：</span>
           <a-select v-model="sound" style="width: 150px">
                <a-select-option
                    v-for="(itme, index) in measure.alarmSounds"
                    :key="index" v-model="itme.no" >
                    {{ itme.text }}
                </a-select-option>
            </a-select>
         
        </li>
      </ul>
    </div>
    <!-- 统计面板  数字  -->
    <div class="statistics" v-if="objectType==3 || objectType==4|| objectType===5">
     
      <!-- 计量 -->
      <div class="measure">
        <div class="measure-name">
          <a-input :class="namecls" v-model="accum.signalName" :title="$t('energy.enedata401')"></a-input> 
          <a-tooltip :title="$t('energy.enedata306')"  placement="right" style="color: #7682ce;font-size:18px; padding-top: 6px; padding-left: 5px;">
              <a-icon type="question-circle-o" />
          </a-tooltip> 
        </div>
        <!-- 状态值 -->
        <span class="measure-header">{{$t('energy.enedata141')}}</span>
        <div class="measure-main">
          <p class="measure-main-t">{{measure.status}}</p>         
        </div>
        <span class="measure-main-b">{{measure.changeDatetime}}</span>
        <div class="measure-footer" v-if="objectType===4">
          <span> {{$t('energy.enedata151')}}：</span>
          <a-select v-model="binaryOpea" style="width: 100px">
            <a-select-option
                v-for="(item, index) in measure.statusList"
                :key="index" :value="item.no">
                {{ item.text }}
            </a-select-option>
          </a-select>
          <a-button type="primary" @click="handleBinaryAction">
              {{$t('energy.enedata023')}}
          </a-button>
        </div>
        <div class="measure-footer" v-else>
        </div>
      </div>
      <!-- 时数据和日数据 binaryOpea,handleBinaryAction -->
      <!-- <div class="statistics-realTime">
        
      </div> -->
      <ul class="operate">
        <li class="operate-li">
          <!-- 维护登记 -->
          <span>{{$t('energy.enedata111')}}</span>
          <div>
            <a-switch v-model="measure.maintenance" />
          </div>
        </li>
        <li class="operate-li">
          <span>{{$t('energy.enedata142')}}：{{measure.on}}</span><span>{{$t('energy.enedata143')}}：{{measure.off}}</span>
        </li>
        <li class="operate-li">
          
           <span>{{$t('energy.enedata114')}}：</span>
           <a-select v-model="sound" style="width: 150px">
                <a-select-option
                    v-for="(itme, index) in measure.alarmSounds"
                    :key="index" v-model="itme.no" >
                    {{ itme.text }}
                </a-select-option>
            </a-select>
         
        </li>
      </ul>
    </div>
    <!-- 统计面板  多态  -->
    <div class="statistics" v-if="objectType==13 || objectType==14 || objectType===19">
     
      <!-- 计量 -->
      <div class="measure-multi">
        <div class="measure-name">
          <a-input :class="namecls" v-model="measure.name" :title="$t('energy.enedata401')"></a-input> 
          <a-tooltip :title="$t('energy.enedata306')"  placement="right" style="color: #7682ce;font-size:18px; padding-top: 6px; padding-left: 5px;">
              <a-icon type="question-circle-o" />
          </a-tooltip> 
        </div>
        <span class="measure-header">{{$t('energy.enedata141')}}</span>
        <div class="measure-main">
          <p class="measure-main-t">{{measure.multiStateValue}}</p>         
        </div>
         <span class="measure-main-b">{{measure.changeDatetime}}</span>
         <div>
            <span>{{$t('energy.enedata152')}}：</span>
            
                <!-- <a-button v-model="measure.presentValue"
                      v-for="(item, index) in measure.multiStateList"
                      :key="index" :value="item.no" >
                      {{ item.text }}
                </a-button> -->
                <a-radio-group v-model="measure.presentValue"  button-style="solid" :disabled="!measure.maintenance">
                  <a-radio-button  v-for="(item,index) in measure.multiStateList" :key="index" :value="parseInt(item.no)"  >{{item.text}}</a-radio-button>
                </a-radio-group>
         </div>
        <!-- <div>
          <span> {{$t('energy.enedata151')}}：</span>
          <a-select v-model="measure.presentValue" style="width: 100px">
            <a-select-option
                v-for="(itme, index) in measure.multiStateList"
                :key="index" :value="parseInt(item.no)" >
                {{ itme.text }}
            </a-select-option>
          </a-select>
          &nbsp;
          <a-button type="primary" @click="handleEdit">
              {{$t('energy.enedata023')}}
          </a-button>
        </div> -->
      </div>

      <ul class="operate-multi">
        <li class="operate-li">
          <span>{{$t('energy.enedata111')}}</span>
          <div>
            <a-switch v-model="measure.maintenance" />
          </div>
        </li>
        <li class="operate-li">
          <!-- <span> {{$t('energy.enedata151')}}：</span>
           <div>
          <a-select v-model="measure.presentValue" style="width: 100px">
            <a-select-option
                v-for="(itme, index) in measure.multiStateList"
                :key="index" :value="parseInt(itme.no)" >
                {{ itme.text }}
            </a-select-option>
          </a-select>
          &nbsp;
          <a-button type="primary" @click="handleEdit">
              {{$t('energy.enedata023')}}
          </a-button> 
        </div>
          -->
        </li>
          <li class="operate-li">
            &nbsp;
          </li>
           <li class="operate-li">
            &nbsp;
          </li>
      </ul>
    </div>

    <!-- 关联信号点 -->
    <div class="box" v-if="hassignal">
         <div class="box-title ">
           <!-- 关联信号点 -->
           <span>{{$t('energy.enedata130')}}</span>
        </div>
        <div class="box-list">
            <div v-for="(item, index) in signals" :key="index" class="box-signal" @click="goSignalDetails(item)" :title="item.name">
                 <div class="box-head">
                        <div class="box-icon"> 
                          <img :src="getImages2(item.iconNo)" :class="getCls"  />
                        </div>
                        
                        <div class="box-body" v-if="item.type===0 || item.type===1 || item.type===2">
                            <div class="box-value">{{ item.presentValue }}</div>
                            <div class="box-unit">{{ item.unit==='功率因素'?'':item.unit }}</div>
                        </div>
                        <div class="box-body" v-if="item.type===3 || item.type===4 || item.type===5">
                            <div class="box-value">{{ item.unit }}</div>
                            <div class="box-unit"></div>
                        </div>
                        <div class="box-body" v-if="item.type===13 || item.type===14 || item.type===19">
                            <div class="box-value">{{ item.unit }}</div>
                            <div class="box-unit"></div>
                        </div>
                        <div class="box-body" v-if="item.type===23">
                            <div class="box-value">{{ item.presentValue }}</div>
                            <div class="box-unit">{{ item.unit }}</div>
                        </div>
                  </div>
                  <div class="box-body">
                      <div class="box-name">{{ item.name }}</div>
                  </div>
            </div>
        </div>
    </div>

    <!-- 上下限值监视 累计信号信息-->
    <div class="box" v-if="objectType==23">
        <div class="monitor-title">
            <span>{{$t('energy.enedata115')}}</span>
            <!-- 使用率(使用量/速率间隔)  当前值 -->
            <span>{{$t('energy.enedata122')}} &nbsp; {{$t('energy.enedata123')}} &nbsp; {{monitor.pulseRate}} &nbsp; </span>
        </div>
        <ul class="monitor-ul">
        <li class="monitor-li">
            <div class="grid">
              <!-- 上限值异常监视 -->
                 <a-checkbox v-model="monitor.maxMonitor" id="maxMonitor"
                     @change="handleChange(1)">{{$t('energy.enedata116')}}</a-checkbox>
            </div>
            <div class="grid-two">
              <!-- 使用率的上限值 -->
                <span>{{$t('energy.enedata118')}}</span>
            </div>
            <div :class="highLimitcls">
                 <a-input-number id="highLimit" class="input-width" v-model="monitor.highLimit" 
                 :disabled="!monitor.maxMonitor"  :min="0" :max="999999"></a-input-number>
            </div>
        </li>
        <li class="monitor-li">
            <div class="grid">
              <!-- 下限值异常监视 -->
                <a-checkbox v-model="monitor.minMonitor" id="minMonitor"
                     @change="handleChange(2)">{{$t('energy.enedata117')}}</a-checkbox>
            </div>
            <div class="grid-two">
              <!-- 使用率的下限值 -->
            <span>{{$t('energy.enedata119')}}</span>
            </div>
            <div :class="lowLimitcls">
                <a-input-number id="lowLimit" class="input-width" v-model="monitor.lowLimit" 
                :disabled="!monitor.minMonitor" :min="0" :max="999999"></a-input-number>
            </div>
        </li>
        <li class="monitor-li">
            <div class="grid"></div>
            <div class="grid-two">
              <!-- 速率间隔 -->
                <span>{{$t('energy.enedata120')}}</span>
            </div>
            <div :class="intvlcls">
                <a-input-number class="input-width " :min="1" :max="86400" v-model="monitor.limitMonitoringInterval" :disabled="!monitor.minMonitor && !monitor.maxMonitor"></a-input-number>
            </div>
            <!-- 秒 -->
            <span>{{$t('energy.enedata121')}}</span>
        </li>
        </ul>
    </div>

    <!-- 监视项 模拟 -->
    <div class="box"  v-if="objectType==0 || objectType==1 || objectType===2">
        <div class="monitor-title">
            <span>{{$t('energy.enedata115')}}</span>
        </div>
        <!-- 上下限值监视 - 模拟 -->
        <ul class="monitor-ul">
          <li class="monitor-li">
              <div class="grid">
                  <a-checkbox v-model="monitor.maxMonitor" @change="handleChange(1)" id="maxMonitor">{{$t('energy.enedata116')}}</a-checkbox>
              </div>
              <div class="grid-two">
                  <span>{{$t('energy.enedata132')}}</span>
              </div>
              <div :class="highLimitcls">
                  <a-input class="input-width" v-model="monitor.high" :disabled="!monitor.maxMonitor"></a-input>
              </div>
          </li>
          <li class="monitor-li">
              <div class="grid">
              <a-checkbox v-model="monitor.minMonitor"  @change="handleChange(2)" id="minMonitor">{{$t('energy.enedata117')}}</a-checkbox>
              </div>
              <div class="grid-two">
              <span>{{$t('energy.enedata133')}}</span>
              </div>
              <div :class="lowLimitcls">
              <a-input class="input-width" v-model="monitor.low" :disabled="!monitor.minMonitor"></a-input>
              </div>
          </li>
          <li class="monitor-li">
              <div class="grid"></div>
              <div class="grid-two">
              <span>{{$t('energy.enedata134')}}</span>
              </div>
              <div :class="intvlcls">
              <a-input class="input-width" v-model="monitor.fixed"></a-input>
              </div>
              <!-- 0~（上限值-下限值）范围内设置 -->
              <span>{{$t('energy.enedata135')}}</span>
          </li>
        </ul>
    </div>

 <!-- 维护监视 - 数字 -->
    <div class="box"  v-if="objectType==3 || objectType==4 || objectType===5">
        <div class="monitor-title">
          <!-- 维护监视 -->
            <span> {{$t('energy.enedata144')}}</span>
        </div>
        <!-- 维护监视 - 数字 -->
         <ul class="monitor-ul" v-if="objectType===4 && cos!=null">
          <!-- 维护监视 - COS  BINARY-OUT -->
          <li class="monitor-li-binary">
               <div class="box-grid">
                <!-- 条件不一致监视 -->
                <a-checkbox v-model="cos.cosCondition"  @change="handleChange(16)" id="cosCondition">
                  {{$t('energy.enedata153')}}
                </a-checkbox>
                <!-- 监控时间 -->
                <span>{{$t('energy.enedata154')}} ：&nbsp;&nbsp;{{cos.time}} &nbsp;{{$t('energy.enedata121')}}</span>
              </div>
              <div class="box-grid-low">
                <!-- 设置值 -->
                <span class="box-grid-s">{{$t('energy.enedata155')}}</span>
                <a-input-number class="input-width" v-model="cos.time" :min="0" :max="255"  :disabled="!cos.cosCondition"></a-input-number>
                <span>{{$t('energy.enedata121')}}</span>
              </div>
          </li>

        </ul>

        <ul class="monitor-ul" v-if="objectType===3 && monitor.alertTime!=null">
          <!-- 维护监视 - 故障  BINARY-INPUTT -->
          <li class="monitor-li-binary">
               <div class="box-grid">
                <a-checkbox v-model="monitor.alertTime.over"  @change="handleChange(14)" id="alertTimeOver">
                  <!-- 故障报警时间超过故障监视报警时间 -->
                  {{$t('energy.enedata156')}}
                </a-checkbox>
              
                <a-input-number class="input-width" v-model="monitor.alertTime.overCount" :disabled="!monitor.alertTime.over" :min="0" :max="65500"></a-input-number>
                <span>{{$t('energy.enedata157')}}</span>
              </div>
              <div class="box-grid-low">
                <span class="box-grid-s">{{$t('energy.enedata132')}}</span>
                <a-input-number class="input-width" v-model="monitor.alertTime.max" :disabled="!monitor.alertTime.over" :min="0" :max="65500" style="margin-left:5px;"></a-input-number>
                <span>{{$t('energy.enedata157')}}</span>
              </div>
          </li>
          <!-- 故障报警次数超过故障监视报警次数 -->
          <li class="monitor-li-binary">
             
                    <div class="box-grid">
                      <a-checkbox v-model="monitor.alertTimes.over"  @change="handleChange(15)" id="alertTimesOver">
                        {{$t('energy.enedata158')}}
                      </a-checkbox>
                      <a-input-number class="input-width" v-model="monitor.alertTimes.overCount" :disabled="!monitor.alertTimes.over" :min="0" :max="65500"></a-input-number>
                      <span>{{$t('energy.enedata159')}}</span>
                    
                    </div>
                
                    <div class="box-grid-low">
                      <span class="box-grid-s">{{$t('energy.enedata133')}}</span>
                      <a-input-number class="input-width" v-model="monitor.alertTimes.max" :disabled="!monitor.alertTimes.over" :min="0" :max="65500" style="margin-left:5px;"></a-input-number>
                      <span>{{$t('energy.enedata159')}}</span>
                    </div>
              
              
          </li>
        </ul>

         <ul class="monitor-ul"  v-if="objectType===3 && monitor.cumulativeTime!=null">
           <!-- 维护监视 - 状态  BINARY-INPUTT -->
           <li class="monitor-li-binary">
             <div class="box-grid">
                <a-checkbox v-model="monitor.cumulativeTime.over"  @change="handleChange(11)" id="cumulativeTimeOver">
                  <!-- 累计运行时间超过监控点运行时间 -->
                   {{$t('energy.enedata160')}}
                </a-checkbox> 
                <a-input-number class="input-width" v-model="monitor.cumulativeTime.overCount" :disabled="!monitor.cumulativeTime.over" :min="0" :max="65500"></a-input-number>
                <span>{{$t('energy.enedata157')}}</span>
              </div>
              <div class="box-grid-low">
                <span class="box-grid-s">{{$t('energy.enedata133')}}</span>
                <a-input-number class="input-width" v-model="monitor.cumulativeTime.max" :disabled="!monitor.cumulativeTime.over" :min="0" :max="65500" style="margin-left:5px;"></a-input-number>
                <span>{{$t('energy.enedata157')}}</span>
              </div>
          </li>
          <li class="monitor-li-binary">
            <div class="box-grid">
                <a-checkbox v-model="monitor.continuousTime.over"  @change="handleChange(12)" id="continuousTimeOver">
                  <!-- 连续运行时间超过监视连续运行时间 -->
                   {{$t('energy.enedata161')}}
                </a-checkbox>
                <a-input-number class="input-width" v-model="monitor.continuousTime.overCount" :disabled="!monitor.continuousTime.over" :min="0" :max="65500" :readonly="ireadonly"></a-input-number>
                <span>{{$t('energy.enedata157')}}</span>
              </div>
              <div class="box-grid-low">
                <span class="box-grid-s">{{$t('energy.enedata133')}}</span>
                <a-input-number class="input-width" v-model="monitor.continuousTime.max" :disabled="!monitor.continuousTime.over" :min="0" :max="65500" style="margin-left:5px;"></a-input-number>
                <span>{{$t('energy.enedata157')}}</span>
              </div>
          </li>
           <li class="monitor-li-binary">
              <div class="box-grid">
                <a-checkbox v-model="monitor.statusChangeTimes.over"  @change="handleChange(13)" id="statusChangeTimesOver">
                  <!-- 状态变化次数超过监视状态变化次数 -->
                   {{$t('energy.enedata162')}}
                </a-checkbox>
              
                <a-input-number class="input-width" v-model="monitor.statusChangeTimes.overCount" :disabled="!monitor.statusChangeTimes.over" :min="0" :max="65500"></a-input-number>
                <span>{{$t('energy.enedata159')}}</span>
              </div>
              <div class="box-grid-low">
                <span class="box-grid-s">{{$t('energy.enedata133')}}</span>
                <a-input-number class="input-width" v-model="monitor.statusChangeTimes.max" :disabled="!monitor.statusChangeTimes.over" :min="0" :max="65500" style="margin-left:5px;"></a-input-number>
                <span>{{$t('energy.enedata159')}}</span>
              </div>
          </li>
        </ul>
    </div>
    
    <!-- 趋势间隔设置 -->
    <div class="box" v-if="measure.pvTrendIdentifier">
        <div class="monitor-title">
            <span>{{$t('energy.enedata547')}}</span>
            <!-- <span>趋势记录信息</span> -->
        </div>
        <!-- 趋势间隔设置 -->
        <ul class="monitor-ul">
          <li class="monitor-li">            
              <a-descriptions  size="middle">
                <!-- <a-descriptions-item label="记录生成间隔时间"> -->
                <a-descriptions-item  class="has-error" :label="$t('energy.enedata548')">
                    <a-input-number v-model="measure.pvTrendInterval" :min="1" :max="3600" />
                    <span>{{$t('energy.enedata121')}}</span><!-- 秒-->
                </a-descriptions-item>
                <!-- <a-descriptions-item label="趋势读取状态"> -->
                <a-descriptions-item :label="$t('energy.enedata552')">
                  {{measure.trendLastFlag?$t('energy.enedata553'):$t('energy.enedata554')}}
                  <!-- '读取中':'停止读取' -->
                </a-descriptions-item>
                <!-- <a-descriptions-item label="最近读取编号"> -->
                <a-descriptions-item :label="$t('energy.enedata556')">
                  {{measure.trendLastId}}
                </a-descriptions-item>
                <!-- <a-descriptions-item label="读取间隔时间"> -->
                <a-descriptions-item :label="$t('energy.enedata549')">
                   <span>{{measure.trendSkip}}</span> 
                   <!-- <span>分钟</span> -->
                   <span>{{$t('energy.enedata551')}}</span>
                </a-descriptions-item>

                <!-- <a-descriptions-item label="最近读取时间"> -->
                <a-descriptions-item :label="$t('energy.enedata555')">
                  {{measure.trendLastExecute}}
                </a-descriptions-item>
                <!-- <a-descriptions-item label="最新趋势记录时间"> -->
                <a-descriptions-item :label="$t('energy.enedata550')">
                  {{measure.trendLastDate}}
                </a-descriptions-item>
                <!-- <a-descriptions-item label="Official">
                  $60.00

                </a-descriptions-item> -->

                <!-- <a-descriptions-item  class="has-error" :label="$t('energy.enedata606')">
                    <a-tree-select
                        v-model="parentId"
                        style="width: 100%"
                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                        :tree-data="grList"
                        :replace-fields="{ label: 'text', value: 'no', children: 'children' }"
                        placeholder="Please select"
                        tree-default-expand-all
                        @change="pointGrParentChange"
                    >
                    
                    </a-tree-select>
                </a-descriptions-item>
                <a-descriptions-item :label="$t('energy.enedata607')">
                    <a-select v-model="iconNo" style="width: 150px" > 
                        <a-select-option
                            v-for="(itme, index) in iconList"
                            :key="index" :value="itme.no" >
                            {{ itme.text }}
                        </a-select-option>
                    </a-select>
                </a-descriptions-item> -->

              </a-descriptions>
          </li>          
        </ul>
    </div>


    <div class="box" >
        <div class="monitor-title">
            <!-- <span>{{$t('energy.enedata547')}}</span> -->
            <span>设备信息</span>
        </div>
        <!-- 设备信息设置 -->
        <ul class="monitor-ul">
          <li class="monitor-li">            
              <a-descriptions  size="middle">
                <a-descriptions-item  class="has-error" :label="$t('energy.enedata606')">
                    <a-tree-select
                        v-model="parentId"
                        style="width: 100%"
                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                        :tree-data="grList"
                        :replace-fields="{ label: 'text', value: 'no', children: 'children' }"
                        placeholder="Please select"
                        tree-default-expand-all
                        @change="pointGrParentChange"
                    >
                    
                    </a-tree-select>
                </a-descriptions-item>
                <a-descriptions-item :label="$t('energy.enedata607')">
                    <a-select v-model="iconNo" style="width: 150px" > 
                        <a-select-option
                            v-for="(itme, index) in iconList"
                            :key="index" :value="itme.no" >
                            {{ itme.text }}
                        </a-select-option>
                    </a-select>
                </a-descriptions-item>
                
                
              </a-descriptions>
          </li>          
        </ul>
    </div>

    <!-- 评论登记栏 -->
    <div class="box">
       <div class="box-title " :title="$t('energy.enedata126')">
         <!-- 评论注册栏 -->
           {{$t('energy.enedata124')}}
       </div>
        <div class="register">
            <ul class="register-ul">
                <li class="register-li">
                    <div class="register-li-item">
                        <span class="input-cmt-sn"> 1</span>
                        <a-input :class="comment1cls" v-model="comment[0]" id="comment1" name="comment1" :title="comment[0]==''?$t('energy.enedata126'):comment[0]" :placeholder="$t('energy.enedata126')"></a-input>
                    </div>
                </li>
                <li class="register-li">
                    <div class="register-li-item">
                        <span class="input-cmt-sn"> 2</span>
                        <a-input :class="comment2cls" v-model="comment[1]" id="comment2" name="comment2" :title="comment[1]==''?$t('energy.enedata126'):comment[1]" :placeholder="$t('energy.enedata126')"></a-input>
                    </div>
                </li>
                <li class="register-li">
                    <div class="register-li-item">
                        <span class="input-cmt-sn"> 3</span>
                        <a-input :class="comment3cls" v-model="comment[2]" id="comment3" name="comment3"  :title="comment[2]==''?$t('energy.enedata126'):comment[2]" :placeholder="$t('energy.enedata126')"></a-input>
                    </div>
                </li>
            </ul>
            <ul class="register-ul">
                <li class="register-li">
                    <div class="register-li-item">
                        <span class="input-cmt-sn"> 4</span>
                    <a-input :class="comment4cls" v-model="comment[3]" id="comment4" name="comment4"  :title="comment[3]==''?$t('energy.enedata126'):comment[3]" :placeholder="$t('energy.enedata126')"></a-input>
                    </div>
               </li>
                <li class="register-li">
                    <div class="register-li-item">
                        <span class="input-cmt-sn"> 5</span>
                        <a-input :class="comment5cls" v-model="comment[4]" id="comment5" name="comment5"  :title="comment[4]==''?$t('energy.enedata126'):comment[4]" :placeholder="$t('energy.enedata126')"></a-input>
                    </div> 
                </li>
                <li class="register-li">
                  <!-- 最多5条评论 -->
                <p class="register-li-item-p">{{$t('energy.enedata125')}}</p>
                <!-- 清空 -->
                <a-button type="primary" ghost @click="clearComment">{{$t('energy.enedata127')}}</a-button>
                </li>
            </ul>
        </div>
    </div>
    <div class="footer">
      <!-- 刷新 -->
      <a-button type="primary" ghost @click="handleFlush">{{$t('energy.enedata128')}}</a-button>
      <!-- 确认 -->
      <a-button type="primary" @click="handleEdit">{{$t('energy.enedata129')}}</a-button>
    </div>
    </div>
     <issueAnnounceDialog  v-if="lcdmonitorNo>0" :equipmentId="equipmentId" :id="announceId"></issueAnnounceDialog>
  </div>
  
</template>

<script>
import commontTable from "../../lightDetails/components/lightTable";
import issueAnnounceDialog  from "../../energy/issueAnnounceDialog";
import moment from "moment";
import { getEnergyEquipmentDetail,updateEnergyEquipmentDetail,getFACEquipmentDetail,updatePointSignalPresentValue } from "../../../api/energy";

export default {
  data() {
    return {
      loading: false,
      title: "",
      siteId:"",
      equipmentId:"",
      equipmentName:"",
      objectType:-1,
      disp:"",
      sound:"",
      accum:"",
      measure:"",
      monitor:"",
      trend:"",
      signals:[],
      hassignal:false,
      comment:[],
      intvlcls:'grid',
      lowLimitcls:'grid',
      highLimitcls:'grid',
      namecls:'ename',
      comment1cls:'input-cmt',
      comment2cls:'input-cmt',
      comment3cls:'input-cmt',
      comment4cls:'input-cmt',
      comment5cls:'input-cmt',
      ireadonly:true,
      issueAnnounceDialogVisible:false,
      lcdmonitorNo:0,
      announceId:0,
      parentId:"0",
      srcParentId:"0",
      iconNo:"0",
      grList:[],
      iconList:[],
      parentChange:false,
      cos:undefined,
      binaryOpea:undefined,
    };
  },
  mounted() {
    let id = this.$route.query.id;
    this.getDetailData(id);
  },
  methods: {
    handleBinaryAction(){
        console.log("handleBinaryAction",this.objectType,this.measure );
        if(this.binaryOpea!=''){
            let sitegrId  = this.$store.getters.sitegrId;
            let clientId  = this.$store.getters.clientId;
            let siteId    = parseInt(sessionStorage.getItem("siteid"));
            let type      = this.$route.query.kind*1;
            let signalId  = this.$route.query.signalId;
            let param={
                clientId: clientId,
                sitegrId: sitegrId,
                siteId: siteId,
                tenantId: 0,
                signalId: this.measure.id,
                signalType: this.objectType,
                presentValue: this.binaryOpea
              };
            this.loading = true;
            console.log("handleBinaryAction param",param);
            updatePointSignalPresentValue(param)
              .then((res) => {
                  if (res.errorCode == "00") {
                      this.$message.success(res.msg);
                  }else {
                      this.$message.error(res.msg);
                  }        
                  this.loading = false;
              }).catch((err) => {
                  console.log(err);
                  this.loading = false;
              });
        }else{

        }
    },
    pointGrParentChange(value, label, extra){
        if(this.srcParentId === value){
           this.parentChange = false;
        }else{
           this.parentChange = true;
        }
    },
    setTrendData(){
      this.trend = {
          name: "",
          xunit: "5分",
          yunit: "kWh",
          categories: [ ],
          data: [ ]
      }
    },
    getDetailData(id){
        console.log("设备ID:"+id); 
        this.loading = true;
        getFACEquipmentDetail(id)
            .then((res) => {
            console.log("res",res);
            console.log("this.measure",this.measure);
            // ene = res.data;
            this.equipmentName = res.data.equipmentName;
            this.equipmentId = res.data.equipmentId;
            this.equipmentName = res.data.equipmentName;

            if(res.data.lcdmonitorNo){
                this.lcdmonitorNo = res.data.lcdmonitorNo;
            }else{
                this.lcdmonitorNo = 0;
            }
            if(res.data.announceId){
                this.announceId = res.data.announceId;
            }else {
                this.announceId = 0;
            }

            this.parentId = res.data.parentId+"";
            this.srcParentId = this.parentId;
            this.parentChange = false;
            this.grList = res.data.grlist;
            this.iconNo = res.data.iconNo+"";
            this.iconList = res.data.iconList;

            if(res.data.displayObjectType==null){
              this.$message.error(this.$t("energy.enedata138"));//数据加载失败");
              this.loading = false;
              return;
            }



            this.objectType = res.data.displayObjectType;
            switch(res.data.displayObjectType){
              case 0:
              case 1:
              case 2:
                this.accum = res.data.analogObjectDataVO;
                this.measure=this.accum.measureInfo;
                this.monitor=this.accum.monitor;
                console.log( this.measure);
                this.trend=this.accum.trendData;
                this.comment=this.accum.comment;
                break;
              case 3:
              case 4:
              case 5:
                this.accum = res.data.binaryObjectDataVO;
                if(this.accum.kind == 1){
                  this.measure=this.accum.statusInfo;
                }else if(this.accum.kind == 2){
                  this.measure=this.accum.operationInfo;
                  this.cos={cosCondition:this.accum.cosCondition,time:this.accum.time}
                  if("1"===this.measure.presentValue){
                    this.binaryOpea = '0';
                  }else{
                    this.binaryOpea = '1';
                  }
                }else if(this.accum.kind == 4){
                  this.measure=this.accum.alertInfo;
                }
                this.monitor=this.accum.monitor;
                console.log( this.measure);
                this.trend=this.accum.trendData;
                this.comment=this.accum.comment;
                break;
              case 13:
              case 14:
              case 19:
                this.accum = res.data.multiStateObjectDataVO;
                this.measure=this.accum.measureInfo;
                this.monitor=this.accum.monitor;
                console.log( this.measure);
                this.trend=this.accum.trendData;
                this.comment=this.accum.comment;
                break;
              case 23:
                this.accum = res.data.accumulatorObjectDataVO;
                this.measure=this.accum.measureInfo;
                this.monitor=this.accum.monitor;
                console.log( this.measure);
                this.trend=this.accum.trendData;
                this.comment=this.accum.comment;
                break;
            }
            this.signals=res.data.pointSignals;
            this.hassignal = res.data.pointSignals!=null && res.data.pointSignals.length>0;
            console.log("hassignal>>>",this.hassignal);
            this.disp = this.measure.displayType+"";
            this.sound = this.measure.voiceType+"";
            if(!this.trend){
              this.setTrendData();
            }
            if(this.objectType!==4){

              setTimeout(()=>{ 
                this.drawLine(this.trend, this.objectType);
              },100);
            }
           
            this.initial();
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            this.loading = false;
        });

        window.addEventListener("resize", this.chartResize());
    },
    goBack() {
      this.$router.go(-1);
    },
    initial(){
      this.intvlcls='grid';
      this.lowLimitcls='grid';
      this.highLimitcls='grid';
      this.namecls='ename';
      this.comment1cls='input-cmt';
      this.comment2cls='input-cmt';
      this.comment3cls='input-cmt';
      this.comment4cls='input-cmt';
      this.comment5cls='input-cmt';

      this.srcParentId = this.parentId;
      this.parentChange = false;
    },
    vailString(name,value,min,max){
        let regEn = /[`~!@#$%^&*+<>?:",\/;']/im;
        let code = '';

        let err = 0;
        if(''===value){
          code = "energy.enedata307";
          err = 1;
        } else if(value.length<min || value.length>max){
          let msg = this.$t("energy.enedata308");
          msg = msg.replace("{text}",name);
          msg = msg.replace("{star}",min);
          msg = msg.replace("{end}",max);
          err = 1;
           return {err: err,msg:msg};
        } else if(regEn.test(value)) {
           code = "energy.enedata309";
           err = 1;
        } else {
           err = 0;
           return {err: err,msg:''};
        }

        let msg = this.$t(code);
        msg = msg.replace("{text}",name);
        return {err: err,msg:msg};
    },
    checkNumber(name,val,min,max){
        let num = new Number(val);
        let err = 0;
        if(isNaN(num)){
          err = 1;
        }
        if(val*1 < min*1 || val*1 > max*1){
           err = 2;
        }
        let emsg = '';
        if(err!=0){
            if(err==2){
                emsg = this.$t('energy.enedata372');
                emsg = emsg.replace('{name}',name);
                emsg = emsg.replace('{min}',min);
                emsg = emsg.replace('{max}',max);
            }else{
                emsg = this.$t('energy.enedata373');
                emsg = emsg.replace('{name}',name);
            }
        }
        return {err:err,msg:emsg};
    },
    handleAccumEdit(){
      let emsg = '';
      let err = 0;

      this.namecls = 'ename';
      let errInfo = this.vailString(this.$t('energy.enedata306'),this.equipmentName,0,50);
      if(errInfo.err>0){
          err = errInfo.err;
          this.namecls = 'ename vail-error';
          this.$message.error(errInfo.msg);
      }


      let cname = this.$t('energy.enedata124');
      for (let i = 0; i < this.comment.length; i++) {
          if(this.comment[i]!=''){
              errInfo = this.vailString(cname +(i+1),this.comment[i],0,200);
              if(errInfo.err>0){
                err = errInfo.err;
                if(i==0)this.comment1cls='input-cmt vail-error';
                else if(i==1)this.comment2cls='input-cmt vail-error';
                else if(i==2)this.comment3cls='input-cmt vail-error';
                else if(i==3)this.comment4cls='input-cmt vail-error';
                else if(i==4)this.comment5cls='input-cmt vail-error';
                this.$message.error(errInfo.msg);
              }
              else{
                if(i==0)this.comment1cls='input-cmt';
                else if(i==1)this.comment2cls='input-cmt';
                else if(i==2)this.comment3cls='input-cmt';
                else if(i==3)this.comment4cls='input-cmt';
                else if(i==4)this.comment5cls='input-cmt';
              }
          }
      }

      this.lowLimitcls = 'grid';//('has-error');
      if(this.monitor.minMonitor){
          errInfo = this.checkNumber(this.$t('energy.enedata119'),this.monitor.lowLimit,0,99999);
          if(errInfo.err>0){
            err = errInfo.err;
            this.lowLimitcls = 'grid has-error';
            this.$message.error(errInfo.msg);
          }
      }

      this.highLimitcls = 'grid';//('has-error');
      if(this.monitor.maxMonitor){
          errInfo = this.checkNumber(this.$t('energy.enedata118'),this.monitor.highLimit,0,99999);
          if(errInfo.err>0){
            err = errInfo.err;
            this.highLimitcls = 'grid has-error';
            this.$message.error(errInfo.msg);
          }
      }
      if(this.monitor.minMonitor && this.monitor.maxMonitor){
          if(this.monitor.highLimit*1 < this.monitor.lowLimit*1){
            err = 1;
            this.$message.error(this.$t('energy.enedata374'));
            this.lowLimitcls='grid has-error';
            this.highLimitcls='grid has-error';
          }
      }
      this.intvlcls = 'grid';//('has-error');
      if(this.monitor.minMonitor || this.monitor.maxMonitor){
          errInfo = this.checkNumber(this.$t('energy.enedata120'),this.monitor.limitMonitoringInterval,1,86400);
          if(errInfo.err>0){
            err = errInfo.err;
            this.intvlcls = 'grid has-error';
            this.$message.error(errInfo.msg);
          }
      }

      if(err>0)
          return;
      let sitegrId  = this.$store.getters.sitegrId;
      let clientId  = this.$store.getters.clientId;
      let siteId = parseInt(sessionStorage.getItem("siteid"));
      
      let param = {
          action:           2,
          clientId:         clientId,
          sitegrId:         sitegrId,
          siteId:           siteId,
          tenantId:         0,
          equipmentId:      this.equipmentId,
          equipmentName:    this.equipmentName,
          signalId:         this.measure.id,
          objectType:       this.measure.objectType,
          voiceType:        this.sound,
          maintenance:      this.measure.maintenance,
          displayType:      this.disp,
          maxMonitor:       this.monitor.maxMonitor,
          minMonitor:       this.monitor.minMonitor,
          highLimit:        this.monitor.highLimit,
          lowLimit:         this.monitor.lowLimit,
          limitMonitoringInterval:this.monitor.limitMonitoringInterval,
          comment:          this.comment,
          pvTrendInterval: this.measure.pvTrendInterval,
          parentId:this.parentId,
          parentChange:this.parentChange,
      }
      console.log(param);
      this.$confirm({
        // title: "确定要进行设定内容注册吗?",
        title:this.$t("energy.enedata184"),
        centered: true,
        onOk: () => {
            this.loading = true;
            updateEnergyEquipmentDetail(param) .then((res) => {
                    console.log("Update Facility Equipment  res");
                    console.log(res);
                    this.loading = false;
                    if (res.errorCode == "00") {
                        this.$message.success(res.msg);
                        this.initial();
                    } else { //if (res.errorCode == "02") 
                        this.$message.error(res.msg);
                    }          
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
            }
        });
    },
    handleAnalogEdit(){

        let emsg = '';
        let err = 0;

        this.namecls = 'ename';
        let errInfo = this.vailString(this.$t('energy.enedata306'),this.equipmentName,0,50);
        if(errInfo.err>0){
            err = errInfo.err;
            this.namecls = 'ename vail-error';
            this.$message.error(errInfo.msg);
        }


        let cname = this.$t('energy.enedata124');
        for (let i = 0; i < this.comment.length; i++) {
            if(this.comment[i]!=''){
                errInfo = this.vailString(cname +(i+1),this.comment[i],0,200);
                if(errInfo.err>0){
                  err = errInfo.err;
                  if(i==0)this.comment1cls='input-cmt vail-error';
                  else if(i==1)this.comment2cls='input-cmt vail-error';
                  else if(i==2)this.comment3cls='input-cmt vail-error';
                  else if(i==3)this.comment4cls='input-cmt vail-error';
                  else if(i==4)this.comment5cls='input-cmt vail-error';
                  this.$message.error(errInfo.msg);
                }
                else{
                  if(i==0)this.comment1cls='input-cmt';
                  else if(i==1)this.comment2cls='input-cmt';
                  else if(i==2)this.comment3cls='input-cmt';
                  else if(i==3)this.comment4cls='input-cmt';
                  else if(i==4)this.comment5cls='input-cmt';
                }
            }
        }
        let h = this.measure.highValueRange*1;
        let l = this.measure.lowValueRange*1;
        let fv = 0;
        this.lowLimitcls = 'grid';//('has-error');
        if(this.monitor.minMonitor){
            errInfo = this.checkNumber(this.$t('energy.enedata133'),this.monitor.low,l,h);
            if(errInfo.err>0){
              err = errInfo.err;
              this.lowLimitcls = 'grid has-error';
              this.$message.error(errInfo.msg);
            }
        }

        this.highLimitcls = 'grid';//('has-error');
        if(this.monitor.maxMonitor){
            errInfo = this.checkNumber(this.$t('energy.enedata132'),this.monitor.high,l,h);
            if(errInfo.err>0){
              err = errInfo.err;
              this.highLimitcls = 'grid has-error';
              this.$message.error(errInfo.msg);
            }
        }

        if(this.monitor.minMonitor && this.monitor.maxMonitor){
            if(this.monitor.high*1 < this.monitor.low*1){
              err = 1;
              this.$message.error(this.$t('energy.enedata374'));
              this.lowLimitcls='grid has-error';
              this.highLimitcls='grid has-error';
              return;
            }
            h = this.monitor.high - this.monitor.low;
            fv = 1;
        }


        this.intvlcls = 'grid';//('has-error');
        if(this.monitor.minMonitor && !this.monitor.maxMonitor){
            h = this.monitor.low;
            fv = 1;
        }else if(!this.monitor.minMonitor && this.monitor.maxMonitor){
            h = this.monitor.high;
            fv = 1;
        }

        if(fv>0){
            errInfo = this.checkNumber(this.$t('energy.enedata134'),this.monitor.fixed*1,0,h);
            if(errInfo.err>0){
              err = errInfo.err;
              this.intvlcls = 'grid has-error';
              this.$message.error(errInfo.msg);
            }
        }

        if(err>0)
          return;

        let sitegrId  = this.$store.getters.sitegrId;
        let clientId  = this.$store.getters.clientId;
        let siteId = parseInt(sessionStorage.getItem("siteid"));
      
        let param  = {
              action:           2,
              clientId:         clientId,
              sitegrId:         sitegrId,
              siteId:           siteId,
              tenantId:         0,
              equipmentId:      this.equipmentId,
              equipmentName:    this.equipmentName,
              signalId:         this.measure.id,
              objectType:       this.measure.objectType,
              voiceType:        this.sound,
              maintenance:      this.measure.maintenance,
              presentValue:     this.measure.presentValue,
              maxMonitor:       this.monitor.maxMonitor,
              minMonitor:       this.monitor.minMonitor,
              highLimit:        this.monitor.high,
              lowLimit:         this.monitor.low,
              fixed:            this.monitor.fixed,
              maxValue:         this.monitor.max,
              minValue:         this.monitor.min,
              comment:          this.comment,
              pvTrendInterval: this.measure.pvTrendInterval,
              parentId:this.parentId,
              parentChange:this.parentChange,
          }


          console.log('Update Facility Equipment Analog Detail ',param);

          this.$confirm({
          title:this.$t("energy.enedata184"),
          centered: true,
          onOk: () => {
              this.loading = true;
              updateEnergyEquipmentDetail(param) .then((res) => {
                      console.log("Update Facility Equipment  res",res);
                      this.loading = false;
                      if (res.errorCode == "00") {
                          this.$message.success(res.msg);
                          this.initial();
                      } else if (res.errorCode == "02") {
                          this.$message.error(res.msg);
                      }          
                  }).catch((err) => {
                      console.log(err);
                      this.loading = false;
                  });
              }
          });
    },
    handleEdit() {
      console.log("edit");
      if(this.objectType==23){
          this.handleAccumEdit();
      }else if(this.objectType==0 || this.objectType==1 || this.objectType==2){
          this.handleAnalogEdit();
      }else if(this.objectType==3 || this.objectType==4 || this.objectType==5){
          this.handleBinaryEdit();
      }else if(this.objectType==13 || this.objectType==14 || this.objectType==19){
          this.handleMultiStateEdit();
      }
      
      
    },
    handleBinaryEdit(){

      let emsg = '';
      let err = 0;

      this.namecls = 'ename';
      let errInfo = this.vailString(this.$t('energy.enedata306'),this.equipmentName,0,50);
      if(errInfo.err>0){
          err = errInfo.err;
          this.namecls = 'ename vail-error';
          this.$message.error(errInfo.msg);
      }


      let cname = this.$t('energy.enedata124');
      for (let i = 0; i < this.comment.length; i++) {
          if(this.comment[i]!=''){
              errInfo = this.vailString(cname +(i+1),this.comment[i],0,200);
              if(errInfo.err>0){
                err = errInfo.err;
                if(i==0)this.comment1cls='input-cmt vail-error';
                else if(i==1)this.comment2cls='input-cmt vail-error';
                else if(i==2)this.comment3cls='input-cmt vail-error';
                else if(i==3)this.comment4cls='input-cmt vail-error';
                else if(i==4)this.comment5cls='input-cmt vail-error';
                this.$message.error(errInfo.msg);
              }
              else{
                if(i==0)this.comment1cls='input-cmt';
                else if(i==1)this.comment2cls='input-cmt';
                else if(i==2)this.comment3cls='input-cmt';
                else if(i==3)this.comment4cls='input-cmt';
                else if(i==4)this.comment5cls='input-cmt';
              }
          }
      }

     if(err>0)
         return;
      
      let sitegrId  = this.$store.getters.sitegrId;
      let clientId  = this.$store.getters.clientId;
      let siteId = parseInt(sessionStorage.getItem("siteid"));
      let param = {
            action:            2,
            clientId:         clientId,
            sitegrId:         sitegrId,
            siteId:           siteId,
            tenantId:         0,
            equipmentId:      this.equipmentId,
            equipmentName:    this.equipmentName,
            signalId:         this.measure.id,
            signalName:       this.accum.signalName,
            objectType:       this.objectType,
            voiceType:        this.sound,
            maintenance:      this.measure.maintenance,
            presentValue:     this.measure.presentValue,
            comment:          this.comment,
            pointType:        this.accum.kind,
            pvTrendInterval: this.measure.pvTrendInterval,
        }

        if(this.objectType==3){
            if(this.monitor.cumulativeTime!=null){
                param.cumulativeTimeEnable	   = this.monitor.cumulativeTime.over;
                param.cumulativeTimeValue      = this.monitor.cumulativeTime.overCount;
                param.cumulativeTimeLimit      = this.monitor.cumulativeTime.max;
            }
            if(this.monitor.continuousTime!=null){
                param.continuousTimeEnable     = this.monitor.continuousTime.over;
                param.continuousTimeValue      = this.monitor.continuousTime.overCount;
                param.continuousTimeLimit      = this.monitor.continuousTime.max;
            }

            if(this.monitor.statusChangeTimes!=null){
                param.statusChangeTimesEnable  = this.monitor.statusChangeTimes.over;
                param.statusChangeTimesValue   = this.monitor.statusChangeTimes.overCount;
                param.statusChangeTimesLimit   = this.monitor.statusChangeTimes.max;
            }

            if(this.monitor.alertTimes!=null){
                param.alertTimesEnable         = this.monitor.alertTimes.over;
                param.alertTimesValue          = this.monitor.alertTimes.overCount;
                param.alertTimesLimit          = this.monitor.alertTimes.max;
            }

            if(this.monitor.alertTime!=null){
                param.alertTimeEnable          = this.monitor.alertTime.over;
                param.alertTimeValue           = this.monitor.alertTime.overCount;
                param.alertTimeLimit           = this.monitor.alertTime.max;
            }
        }else{
            if(this.cos!=null){
                param.cosEnable                = this.cos.cosCondition;
                param.cosValue                 = this.cos.time;  
            }
        }

        console.log('Update Facility Equipment Binary Detail ',param);
         this.$confirm({
          title:this.$t("energy.enedata184"),
          centered: true,
          onOk: () => {
              this.loading = true;
              updateEnergyEquipmentDetail(param) 
              .then((res) => {
                  console.log("Update Facility Equipment  res",res);
                  this.loading = false;
                  if (res.errorCode == "00") {
                      this.$message.success(res.msg);
                      this.initial();
                  } else  {
                      this.$message.error(res.msg);
                  }          
              })
              .catch((err) => {
                  console.log(err);
                  this.loading = false;
              });
          }
        });
    },
    handleMultiStateEdit(){
      let emsg = '';
      let err = 0;

      this.namecls = 'ename';
      let errInfo = this.vailString(this.$t('energy.enedata306'),this.equipmentName,0,50);
      if(errInfo.err>0){
          err = errInfo.err;
          this.namecls = 'ename vail-error';
          this.$message.error(errInfo.msg);
      }


      let cname = this.$t('energy.enedata124');
      for (let i = 0; i < this.comment.length; i++) {
          if(this.comment[i]!=''){
              errInfo = this.vailString(cname +(i+1),this.comment[i],0,200);
              if(errInfo.err>0){
                err = errInfo.err;
                if(i==0)this.comment1cls='input-cmt vail-error';
                else if(i==1)this.comment2cls='input-cmt vail-error';
                else if(i==2)this.comment3cls='input-cmt vail-error';
                else if(i==3)this.comment4cls='input-cmt vail-error';
                else if(i==4)this.comment5cls='input-cmt vail-error';
                this.$message.error(errInfo.msg);
              }
              else{
                if(i==0)this.comment1cls='input-cmt';
                else if(i==1)this.comment2cls='input-cmt';
                else if(i==2)this.comment3cls='input-cmt';
                else if(i==3)this.comment4cls='input-cmt';
                else if(i==4)this.comment5cls='input-cmt';
              }
          }
      }
      let sitegrId  = this.$store.getters.sitegrId;
      let clientId  = this.$store.getters.clientId;
      let siteId = parseInt(sessionStorage.getItem("siteid"));

      let param = {
          action:           2,
          clientId:         clientId,
          sitegrId:         sitegrId,
          siteId:           siteId,
          tenantId:         0,
          equipmentId:      this.equipmentId,
          equipmentName:    this.equipmentName,
          signalId:         this.measure.id,
          signalName:       this.measure.name,
          objectType:       this.objectType,
          maintenance:      this.measure.maintenance,
          presentValue:     this.measure.presentValue,
          comment:          this.comment,
          pointType:        this.accum.kind,
          pvTrendInterval: this.measure.pvTrendInterval,
          parentId:this.parentId,
          parentChange:this.parentChange,
      };
      console.log('Update Facility Equipment MultiState Detail ',param);

      this.$confirm({
          title:this.$t("energy.enedata184"),
          centered: true,
          onOk: () => {
              this.loading = true;
              updateEnergyEquipmentDetail(param) 
              .then((res) => {
                  console.log("Update Facility Equipment  res",res);
                  this.loading = false;
                  if (res.errorCode == "00") {
                      this.$message.success(res.msg);
                      this.initial();
                  } else  {//if (res.errorCode == "02")
                      this.$message.error(res.msg);
                  }          
              })
              .catch((err) => {
                  console.log(err);
                  this.loading = false;
              });
          }
        });
    },
    handleFlush() {
      console.log("flush");
      let id = this.$route.query.id;
      this.getDetailData(id);
    },
    handleSoundChange(val,opt){
        console.log("handleSoundChange"+val);
    },
    handleDisplayChange(val,opt){
        console.log("handleDisplayChange"+val);
    },
    handleChange(tag){
        // console.log("handleChange "+tag);
        if(tag==0){
            if(this.measure.maintenance){
                this.measure.maintenance = false;
            }else{
                this.measure.maintenance = true;
            }
            console.log("handleChange>>"+this.measure.maintenance);
        }
        // else if(tag==1){
        //     this.monitor.maxMonitor = !this.monitor.maxMonitor;
        // }else if(tag==2){
        //     this.monitor.minMonitor = !this.monitor.minMonitor;
        // }
    },
    clearComment(){
        // console.log("clearComment");
        this.$confirm({
            // title: "确定要清除所有评论内容吗?",
            title:this.$t("energy.enedata140"),
            centered: true,
            onOk: () => {
                this.comment = ["","","","",""];    
        }
        });     
    },
    chartResize(){
        try{
            let o = document.getElementById(this.facilityEquipmentChart);
            if(o!=null){
                this.$echarts.init(document.getElementById(this.facilityEquipmentChart)).resize();
            }
        }catch(e){
            console.log(e);
        } 
    },
    getMarkLineOption(type){
        let mark = {   
              symbol:"none",               //去掉警戒线最后面的箭头
              name:this.$t('energy.enedata132'),
              silent:true,
              data : []
        };    
        let max = 0;
        let min = 0;
        let flag = 0;

        if(type==23 && this.monitor) {
            max = this.monitor.highLimit * this.measure.coefficient;
            min = this.monitor.lowLimit  * this.measure.coefficient;
        } else if((type>=0 && type<=2)  && this.monitor) {
            max = this.monitor.high ;
            min = this.monitor.low  ;
        } else {
            return {  mark,max,min,flag }
        }
        
        if(this.monitor){
            if(this.monitor.maxMonitor){
                let line = {
                    silent:true,             //鼠标悬停事件  true没有，false有
                    lineStyle:{               //警戒线的样式  ，虚实  颜色
                        type:"solid",
                        color:"red"
                    },
                    name: this.$t('energy.enedata132'),
                    yAxis: max,
                };
                
                mark.data.push(line);
                flag++;
            }

            if(this.monitor.minMonitor){
              let line ={
                    silent:true,             //鼠标悬停事件  true没有，false有
                    lineStyle:{               //警戒线的样式  ，虚实  颜色
                        type:"solid",
                        color:"green"
                    },
                    name: this.$t('energy.enedata133'),
                    yAxis: min,
                };
                mark.data.push(line);
                flag++;
            }
        }

        return {  mark,max,min,flag }
    },
    drawLine(trend,type) {
      // 基于准备好的dom，初始化echarts实例
      console.log("drawLine",type);
      console.log("drawLine",trend);

      let myChart = this.$echarts.init(document.getElementById(this.facilityEquipmentChart));
      let trendData = trend.data;
      let categories = trend.categories;
      let unit = trend.yunit+"/"+trend.xunit;
      let tag = this.accum.tagPoint;
      let sname = tag + "-" + trend.name + "-"+this.$t("energy.enedata139")+"（"+unit+"）";
      
      let markLineOpt = this.getMarkLineOption(type);
      console.log('markLineOpt',markLineOpt)
      let markLine = undefined;
      if(markLineOpt.flag>0){
          markLine = markLineOpt.mark;
      }

      // 使用刚指定的配置项和数据显示图表。
      //myChart.setOption(option);

      let titleData = {
          text: sname,
          subtext: trend.yunit,
          left: "6%",
          textStyle: {
              fontSize: 18,
              color: "#7682ce",
              fontWeight: "bold"
          }
      };
      let tooltipData = {
                  trigger: "axis",
                  subtext: trend.yunit,
                };
      let xAxisData =  {
                  type: "category",
                  data: categories
                };
      let yAxisData =  {
                    show:true,
                    axisLine:{show:true},
                    axisTick:{show:true},
                    type: 'value',
                    max:function(value){
                        console.log("max value>>",value)
                        if(markLineOpt.flag>0 && value.max<markLineOpt.max){
                          let mx = new Number(markLineOpt.max*1.1);
                          if(mx>=1)
                              value.max =  mx.toFixed(0);
                            else if(mx<1 && mx>=0.1){
                              value.max =  mx.toFixed(1);
                            }else if(mx<0.1 && mx>=0.01){
                              value.max =  mx.toFixed(2);
                            }else if(mx<0.01 && mx>0.001){
                              value.max =  mx.toFixed(3);
                            }else if(mx<0.001 && mx>0.0001){
                              value.max =  mx.toFixed(4);
                            }
                            return value.max;
                        }
                        if(isNaN(value.max)){
                            if(markLineOpt.max>0){
                                let mx = new Number(markLineOpt.max*1.1);
                                if(mx>=1)
                                  value.max =  mx.toFixed(0);
                                else if(mx<1 && mx>=0.1){
                                  value.max =  mx.toFixed(1);
                                }else if(mx<0.1 && mx>=0.01){
                                  value.max =  mx.toFixed(2);
                                }else if(mx<0.01 && mx>0.001){
                                  value.max =  mx.toFixed(3);
                                }else if(mx<0.001 && mx>0.0001){
                                  value.max =  mx.toFixed(4);
                                }
                            }else{
                                value.max = 1;
                            }
                        } else if(value.max<0){
                            value.max =  0;
                        } else{
                            let mx = new Number(value.max*1.1);
                            if(mx>=1)
                              value.max =  mx.toFixed(0);
                            else if(mx<1 && mx>=0.1){
                              value.max =  mx.toFixed(1);
                            }else if(mx<0.1 && mx>=0.01){
                              value.max =  mx.toFixed(2);
                            }else if(mx<0.01 && mx>0.001){
                              value.max =  mx.toFixed(3);
                            }else if(mx<0.001 && mx>0.0001){
                              value.max =  mx.toFixed(4);
                            }
                            
                        }
                        return value.max;
                    },
                    min:function(value){
                      console.log("min value>>",value)
                      
                      if(isNaN(value.min)){
                          value.min = 0;
                      } else if(value.min<0){
                          let mi = new Number(value.min*1.1);
                          let mx = Math.abs(mi);
                          if(mx>=1)
                              value.min =  mi.toFixed(0);
                            else if(mx<1 && mx>=0.1){
                              value.min =  mi.toFixed(1);
                            }else if(mx<0.1 && mx>=0.01){
                              value.min =  mi.toFixed(2);
                            }else if(mx<0.01 && mx>0.001){
                              value.min =  mi.toFixed(3);
                            }else if(mx<0.001 && mx>0.0001){
                              value.min =  mi.toFixed(4);
                            }
                          // value.min =  value.min*1.1;
                      } else {
                          value.min = 0;
                      }
                      return value.min
                    },
                };
      let toolbox = {
                  show: true,
                  orient: 'horizontal',
                  left: 'right',
                  top: 'top',
                  feature: {
                    mark: { show: true },
                    dataView: { show: false, readOnly: false },
                    magicType: { show: true, type: ['line', 'bar'] },
                    restore: { show: true },
                    saveAsImage: { show: true}
                  }
                };
      let option;
      let chartType = "line";
      switch(type){
          case 0:
          case 1:
          case 2:
          case 23:
              option = {
                title: titleData,
                tooltip: tooltipData,
                toolbox:toolbox,
                legend:  {
                    data:[trend.name]
                },
                // x轴配置
                xAxis:xAxisData,
                // y轴配置
                yAxis: yAxisData,
                series: [
                  {
                    name: trend.name,
                    data: trendData,
                    type: chartType,
                    smooth: true,
                    color: "#7682CE",
                    markLine, 
                  }
                ]
              };
            break;
          case 3:
          case 4:
          case 5:
          case 13:
          case 14:
          case 19:
            unit = trend.xunit;
            titleData.text = tag + "-" + trend.name + "-"+this.$t("energy.enedata139")+"（"+unit+"）";
            option = {
                title:titleData,
                tooltip: {
                  trigger: "axis",
                  formatter: '{b} : <br/> {a}: {c}'
                },
                toolbox:toolbox,
                legend:  {
                    data:[trend.name]
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: xAxisData,
                yAxis: {
                    type: 'category',
                    data: trend.stepLineYAxis
                },
                series: [
                    {
                        name: trend.name,
                        type: 'line',
                        step: 'start',
                        data:  trend.stepLineData,
                        color: "#7682CE",
                    }
                ]
            };
            break;
        }
        console.log("option>>",option);
        // 使用刚指定的配置项和数据显示图表。
        option && myChart.setOption(option);
    },
    goSignalDetails(obj){
        console.log(obj);
        let eqmid = this.$route.query.id;
        let type =  obj.type;
        let signalId = obj.id;
        let iconNo = this.$route.query.iconNo;
        this.$router.push({
          path: "/homepage/facility/facilitySignalDetails",
          query: {
            pgrId: eqmid,
            iconNo:iconNo,
            kind:type,
            signalId:signalId
          }
      });

    },
    setScroll() {
      this.$refs.viewBox.scrollTop = 0;
    },
    getImages2(icon) {
      console.log(icon);
      if(icon){
        return require("../../../../public/images/facility/" + icon + ".png");
      }else{
        let iconNo = this.$route.query.iconNo.toString().substring(0,2);
        return require("../../../../public/images/facility/" + iconNo + "0.png");
      }
    },
    issueAnnounceDialog(){
      this.issueAnnounceDialogVisible = true;
    },
    moment
  },
  computed:{
      // 卡片图标根据对应iocnNO加载对应图标
    getImages: function () {
        if(this.$route.query.iconNo){

          let iconNo = this.$route.query.iconNo;
  
          return require("../../../../public/images/facility/" + iconNo + ".png");
        }else{
          return require("../../../../public/images/facility/0.png");
        }
    },
   
    getCls: function () {
      // let iconNo = parseInt(this.$route.query.iconNo.toString().substring(0,2)+"0");
      let cls = '';
      // switch(iconNo){
      //   case 690:
      //   case 720:
      //   case 730:
      //   case 740:
      //   case 750:
      //   case 760:
      //   case 770:
      //   case 780:
      //   case 790:
      //   case 810:
      //     cls = 'cimg';
      //     break;
      //   default:
      //     break;
      // }
      return cls;
    },
    facilityEquipmentChart() {
          return "facilityEquipmentChart" + Math.floor((Math.random() * 10000) + 1);
    },
  },
  destroyed() {
    window.removeEventListener('resize',this.chartResize());
  },
  components: {
    "c-table":commontTable,
    "issueAnnounceDialog":issueAnnounceDialog,
  }
};
</script>

<style scoped>
ul, li {
  list-style: none;
  margin: 0;
}
.hide{
    display: none;
}
#facilityEquipmentDetails {
  width: calc(100% - 40px);
  height: calc(100% - 30px);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-shadow: 0px 0px 10px #bdbcbc;
  background: #ffffff;
  font-family: "Microsoft YaHei";
  overflow: hidden;
}
.cimg{
  height: 32px;
  margin: 8px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 10px 20px;
  border-bottom: 1px solid #cccdcd;
  white-space: nowrap;
  overflow: hidden;
}
.header-title {
  display: flex;
  align-items: center;
}
.header-title span {
  margin-right: 30px;
}

.detailstitle {
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detailstitle-switch {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #3e3a39;
}
.detailstitle-switch span {
  margin-right: 15px;
}
.line {
  width: calc(100% + 40px);
  margin: 20px 0px 30px -20px;
  border-bottom: 1px solid #dbdcde;
}
.statistics {
  width: 100%;
  height: 230px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow:0 2px 15px #bdbcbc;
  border-radius: 3px;
  padding: 20px;
  overflow: hidden;
}
.statistics-realTime {
  width: 350px;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
}
.circle {
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid #7682ce;
}
.circle-p {
  margin: 0;
  font-size: 30px;
  color: #7682ce;
}
.circle-s {
  font-size: 16px;
}
.measure {
  width: 390px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  padding:2px;
}
.measure-name{
  display: flex;
  justify-content: flex-start;
  padding: 3px;
}
.measure-multi {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
}
.measure-header {
  font-size: 16px;
}
.measure-main-t {
  font-size: 30px;
  color: #7682ce;
  margin: 0;
}
.measure-main-b {
  font-size: 14px;
}
.measure-footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #dfe0e0;
  margin-top: 8px;
  padding: 8px 0px;
}
.measure-footer-p {
  font-size: 18px;
  margin: 0;
}
.measure-footer-s {
  font-size: 12px;
}
.operate {
  width: 350px;
}
.operate-multi {
  width: 350px;
}
.operate-li{
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
}
.operate-li-div{
  width: 230px;
}
.operate-li-div span{
  margin-left: 10px;
}
.border{
  border-top: 1px solid #dfe0e0;
  border-bottom: 1px solid #dfe0e0;
}
.weight {
  padding: 10px 20px;
  font-size: 16px;
  border-bottom: 1px solid #dfe0e0;
}

.box{
    border-radius: 3px;
    border: 1px solid #f3f3f5;
    margin-top: 30px;
}
.box-title {
  height: 50px;
  /* width: calc(100%-60px); */
  background: #f3f3f5;
  line-height: 50px;
  font-size: 15px;
  padding: 0 30px;
  white-space: nowrap;
  overflow: hidden;
}
.box-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 20px 20px 20px 20px;
  overflow: auto;
}
.box-signal{
    height: 80px;
    width: 180px;
    border-radius: 3px;
    border: 1px solid #f3f3f5;
    margin: 3px;
    cursor: pointer;
}

.box-signal:hover {
  border: 1px solid #7682ce;
}
.box-head{
    display: flex;
    justify-content: flex-start;
}
.box-body{
    display: flex;
    justify-content: flex-start;
}
.box-value{
    font-size: 20px;
    margin: 10px 5px;
}
.box-name{
    font-size: 14px;
    margin: 5px;
    text-overflow: ellipsis;
    width: 180px;
    overflow: hidden;
    white-space: nowrap;
}
.box-unit{
    font-size: 16px;
    text-align: right;
    margin: 10px 5px;
}
.box-icon{
    width:  48px;
    height: 48px;
    margin: 0 2px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.box-icon-0{
    background-image: url('../../../../public/images/facility/0.png');
}
.box-icon-1{
    background-image: url('../../../../public/images/facility/0.png');
}
.box-icon-2{
    background-image: url('../../../../public/images/facility/0.png');
}
.box-icon-3{
    background-image: url('../../../../public/images/facility/3.png');
}
.box-icon-4{
    background-image: url('../../../../public/images/facility/3.png');
}
.box-icon-5{
    background-image: url('../../../../public/images/facility/3.png');
}
.box-icon-13{
    background-image: url('../../../../public/images/facility/3.png');
}
.box-icon-14{
    background-image: url('../../../../public/images/facility/3.png');
}
.box-icon-19{
    background-image: url('../../../../public/images/facility/3.png');
}
.box-icon-23{
    background-image: url('../../../../public/images/facility/0.png');
}

.monitor-title {
  height: 50px;
  /* width: calc(100%-60px); */
  background: #f3f3f5;
  line-height: 50px;
  font-size: 15px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
}
.monitor-li-binary {
  font-size: 14px;
  padding: 10px;
  border-bottom: 1px solid #f3f3f5;
  white-space: nowrap;
  overflow: hidden;
}
.monitor-li-binary:last-child{
    border-bottom: 0;
}
.monitor-li {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 10px;
  border-bottom: 1px solid #f3f3f5;
  white-space: nowrap;
  overflow: hidden;
}
.monitor-li:last-child{
    border-bottom: 0;
}
.grid {
  /* width: 200px; */
  width: 280px; 
  display: flex;
  align-items: center;
}
.grid-two {
  width: 200px; 
  display: flex;
  align-items: center;
}
.register {
  display: flex;
}
.register-ul {
  width: 50%;
  padding: 0 10px;
  margin: 0;
}
.register-li {
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  border-bottom: 1px solid #dfe0e0;
  white-space: nowrap;
  overflow: hidden;
}
.register-li:last-child{
    border-bottom: 0;
}
.register-li-item {
  display: flex;
}
.register-li-item-p {
  padding: 0px 10px;
  margin: 0;
}
.register-li-img {
  width: 40px;
  height: 40px;
}
.footer {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.footer button {
  width: 120px;
  margin: 0 20px;
}
.content {
  width: 100%;
  margin: 10px 0;
}
.input-width {
  width: 120px;
  margin-right: 15px;
}

.input-cmt{width:320px;border: none;}
.input-cmt-sn{
    height:32px;line-height:32px;margin-right:10px;
}
.ename{
    border:none;
    height:32px;
    width: 360px;
    font-size:18px;
    color:#000000;
}
.vail-error{
  border:1px solid #f5222d;
}
.box-grid{
   padding:5px;
}
.box-grid-low{
   padding:5px;margin-left:30px;border-top:1px solid #f5f5f5;
}
.main{
  width: 100%;
  height: calc(100% - 68px);
  padding: 10px 20px;
  overflow: auto;
}
</style>